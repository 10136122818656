<template>
    <div>
        <p class="text-2">No Item Found</p>
    </div>
</template>

<script>
export default {
    name: 'FoundItem'
};
</script>

<style scoped>

</style>
